<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ title }}</span>
    </template>
    <p>{{ $t('_.greeting', { nickname }) }}</p>
    <p v-html="description"></p>
    <template v-slot:buttons>
      <button v-if="buttonShow" @click="route">{{ buttonText }}</button>
    </template>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';

export default {
  name: 'NotificationNoticeItem',
  components: { NotificationItem },
  lexicon: 'shared.notification.NOTICE',
  props: {
    message: Specific,
  },
  computed: {
    /** @type {NotificationNoticeInfo} */
    noticeInfo() {
      return this.message?.data?.notice || this.message?.data;
    },
    title() {
      return this.noticeInfo?.title || 'TEST';
    },
    realTime() {
      return Boolean(this.message?.realTime);
    },
    buttonShow() {
      return Boolean(this.noticeInfo?.link);
    },
    buttonText() {
      return this.noticeInfo?.link?.text || this.$t('_.goNow');
    },
    description() {
      return this.noticeInfo?.description;
    },
    nickname() {
      return this.$store.state.auth.authInfo.nickname;
    },
  },
  methods: {
    route() {
      const TARGET_LINKS = {
        POINT_SHOP: '/pointshop/all/',
      };
      const path = this.noticeInfo.link.route || TARGET_LINKS[this.noticeInfo.link.target];
      if (/^https?:/.test(path)) window.open(path);
      else this.routeMainPath({ path });
      this.$emit('read-item', this.message || {});
    },
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
